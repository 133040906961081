'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fab';
var iconName = 'gayl';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M379.4,14.5C311,9.3,249.6,60.9,226.6,144.5c-80-35.9-165.4-24.7-205.3,32.1c-47.3,67.2-13,173.7,77.6,236.9 c119,82.9,292.7,84.3,292.7,84.3c0,0,107.8-131.7,119.6-274.6C520,114.2,460.6,20.9,379.4,14.5z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faGayl = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;