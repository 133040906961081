'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'chrome-pin';
var width = 300;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M33.6 0v47.6h36.5L49.4 290.9H0v47.6l126.7.1-.2 149.7 23.3 23.6 23.4-23.5.1-149.7 126.7.1v-47.6h-46.6L230.9 47.8h36V.2L33.6 0zm83.8 48.1l66 .1 22.2 239.4-108.4-.1 20.2-239.4z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faChromePin = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;