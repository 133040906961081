'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'chrome-ext';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M207.2 0a61 61 0 00-61 61v36.6H48.8A48.6 48.6 0 000 146.3v73.1a24.4 24.4 0 0024.4 24.4h8.7c31.9 0 60.9 22.9 64.1 54.7a60.9 60.9 0 01-60.6 67.2H24.4A24.4 24.4 0 000 390.1v73.1c0 27 21.8 48.8 48.8 48.8h73.1a24.4 24.4 0 0024.4-24.4v-8.7c0-31.9 22.9-60.9 54.7-64.1a60.9 60.9 0 0167.2 60.6v12.2a24.4 24.4 0 0024.4 24.4h73.1a48.8 48.8 0 0048.8-48.8v-97.5H451a61 61 0 100-122h-36.6v-97.5a48.8 48.8 0 00-48.8-48.8h-97.5V61c.1-33.7-27.2-61-60.9-61z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faChromeExt = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;